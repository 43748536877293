import { render, staticRenderFns } from "./SensesChart.vue?vue&type=template&id=4ce2802f&scoped=true&"
import script from "./SensesChart.vue?vue&type=script&lang=js&"
export * from "./SensesChart.vue?vue&type=script&lang=js&"
import style0 from "./SensesChart.vue?vue&type=style&index=0&id=4ce2802f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce2802f",
  null
  
)

export default component.exports