<template functional>
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'>
    <path d='M112,112l20,320c.95,18.49,14.4,32,32,32H348c17.67,0,30.87-13.51,32-32l20-320'/>
    <line x1='80' y1='112' x2='432' y2='112'/>
    <path d='M192,112V72h0a23.93,23.93,0,0,1,24-24h80a23.93,23.93,0,0,1,24,24h0v40'/>
    <line x1='256' y1='176' x2='256' y2='400'/>
    <line x1='184' y1='176' x2='192' y2='400'/>
    <line x1='328' y1='176' x2='320' y2='400'/>
  </svg>
</template>

<script>
  export default {
    name: "TrashIcon"
  }
</script>

<style scoped>
  line {
    stroke: #000;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-width: 32px
  }
  path {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 32px;
  }
</style>